#root {
  background: white;
  min-height: 100vh;
}

// part 2:
.main {
  padding: 0;
  margin-top: 70px;

  @include break-max(991px) {
    margin-top: 120px;
  }
}

.m-table-responsive {
  min-height: 0.01%;

  @include break-max(991px) {
    width: 100%;
    margin-bottom: 15px;
    overflow-y: hidden;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    border: 1px solid #dedede;

    table {
      width: 1000px;
    }
  }

  .-center {
    text-align: center;

    .a-radio {
      justify-content: center;
    }
  }

  .-textRight {
    text-align: right;

    .a-radio {
      justify-content: right;
    }
  }
}

.ReactModalPortal {
  .ReactModal__Overlay--after-open {
    overflow-x: hidden;
    overflow-y: auto;
    top: 0;
    left: 0;
    z-index: 3147483648;
    width: 100%;
    height: 100%;
    outline: 0;
    background-color: $alphaBlack03 !important;
  }

  .ReactModal__Content {
    position: absolute;
    border: 1px solid $pastelGray;
    background: $white;
    overflow: auto;
    outline: none;
    transform: translate(-50%, -50%);
    width: 100%;

    @include break-min(768px) {
      width: 700px;
    }

    .a-button-primary,
    .a-button-outline {
      min-width: 200px;
    }

    // CHANGE PASSWORD MODAL:
    &.m-change-password-modal {
      width: 100%;

      @include break-min(768px) {
        width: 700px;
      }
    }
  }
}

.p-dashboard {
  &-head {
    h3 {
      margin: 0;
      padding-bottom: 20px;

      @include break-min(768px) {
        padding-bottom: 0;
      }
    }
  }

  .m-section-shadow {
    background-color: $whiteSmoke;
  }

  .react-tabs__tab-list {
    width: 100%;
    height: 100%;
    background-color: $white;

    .react-tabs__tab {
      @include break(768px, 1024px) {
        font-size: 12px;
      }
    }
  }

  .react-tabs__tab-panel {
    width: 100%;
    padding: 20px;

    @include break-min(992px) {
      padding: 30px 30px 30px 0;
    }

    .a-heading {
      margin: 0;
      padding-top: 10px;
      padding-bottom: 10px;

      @include break-min(992px) {
        padding-top: 0;
        padding-bottom: 30px;
      }
    }

    .a-select2 {
      margin-bottom: 20px;

      @include break-min(768px) {
        width: 250px;
        float: right;
        margin-bottom: 0;
      }
    }

    form {
      .a-button-secondary {
        width: 100%;
      }
    }
  }

  .m-btn-toolbar {
    @include break-max(640px) {
      margin-top: 0;
    }
  }
}

.css-1okebmr-indicatorSeparator {
  width: 0 !important;
}

.compare-button {
  &.a-button {
    @include break-max(767px) {
      padding: 0;
      width: 20px;
      height: 20px;
      min-height: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: none;

      &:before {
        transition: none;
      }

      &:hover {
        box-shadow: none;
      }
    }

    &:before {
      font-size: 0;
      width: 100%;
      height: 100%;
      margin: -1px;

      // for text:
      @include break-min(768px) {
        font-size: inherit;
        background: none;
        white-space: nowrap;
      }

      // for icon:
      @include break-max(767px) {
        width: 12px !important;
        height: 12px !important;
        background: url("../images/icons/css_sprites-3.webp") -194px -49px;
      }
    }

    &.selected {
      @include break-max(767px) {
        &:before {
          width: 12px !important;
          height: 12px !important;
          background: url("../images/icons/css_sprites-3.webp") -194px -81px;
        }
      }
    }
  }
}

.swiper-button-prev.swiper-button-disabled,
.swiper-button-next.swiper-button-disabled {
  cursor: not-allowed !important;
  pointer-events: visible !important;
}

// common carousel css:
.new-contract-feature-carousel {
  .o-section {
    .m-carousel .swiper {
      padding-top: 30px;
      padding-bottom: 60px;

      .swiper-button-next,
      .swiper-button-prev {
        top: 45%;
      }

      .swiper-button-next,
      .swiper-button-prev {
        display: grid;
      }

      .swiper-button-next {
        right: -75px;
      }

      .swiper-button-prev {
        left: -77px;
      }

      @include break-max(768px) {

        .swiper-button-next,
        .swiper-button-prev {
          display: none;
        }
      }

      @include break-max(1420px) {
        .swiper-button-next {
          right: 0;
        }

        .swiper-button-prev {
          left: 0;
        }
      }
    }

    .swiper-pagination {
      display: flex;
      width: auto;
      left: 53%;
      transform: translateX(-53%);
      background: $white;
      padding: 10px;
      border-radius: 20px;
      bottom: 0px;

      @include break-max(480px) {
        padding: 5px;
        bottom: -10px;
      }
    }
  }
}

.Toastify {
  $root: &;

  &__toast-container {
    z-index: 9999999999 !important;
    padding: 1em;

    #{$root} {
      &__toast {
        @include break-max(576px) {
          min-height: 0;

          &-body {
            padding: 0;
            font-size: 12px;
          }
        }
      }
    }
  }
}

// spacing
.p3 {
  padding: 24px;
}

body[style*="position: static;"] #cs-live-chat {
  @include break-max(1199px) {
    transform: scale(0.85);
    right: unset !important;
    bottom: 46px !important;
    left: -12px !important;
  }
}

body[style*="position: fixed;"] #cs-live-chat {
  @include break-max(1199px) {
    transform: initial !important;
  }
}