// MIXIN

@mixin break-max($pixel) {
  @media (max-width: $pixel) {
    @content;
  }
}

@mixin break-min($pixel) {
  @media (min-width: $pixel) {
    @content;
  }
}

@mixin break($pixel1, $pixel2) {
  @media (min-width: $pixel1) and (max-width: $pixel2) {
    @content;
  }
}

@mixin placeholder {
  &::-webkit-input-placeholder {
    @content;
  }
  &::-moz-placeholder {
    @content;
  }
  &:-ms-input-placeholder {
    @content;
  }
  &:-moz-placeholder {
    @content;
  }
}

@mixin reset-autofill($color: $black) {
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    transition: background 5000s ease-in-out 0s;
    -webkit-text-fill-color: $color;
  }
}

@mixin hover() {
  @media (hover: hover) {
    &:hover {
      @content;
    }
  }
}

// FORM
@mixin reset-button {
  -webkit-appearance: none;
  border: 0;
  border-radius: 0; // Iphone5
  outline: 0;
  cursor: pointer;
}

@mixin reset-input {
  -webkit-appearance: none;
  border: 0;
  border-radius: 0; // Iphone5
  outline: 0;
}

@mixin mobile-down {
  @media (max-width: 767px) {
    @content;
  }
}
