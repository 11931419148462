// Đặt tên theo:
// https://hexcol.com/

// Thêm hậu tố nếu cùng tên:
// -lightest, -lighter, -light, -dark, -darker, -darkest

$richElectricBlue: #0097e0 !default; //Rich Electric Blue //0, 151, 224
$pictonBlue: #54c3f1 !default; //Picton Blue //84, 195, 241
$pastelRed: #ff5f5f !default; //Pastel Red //255, 95, 95
$pastelGray: #cccccc !default; //Pastel Gray //204, 204, 204
$white: #ffffff !default; //White //255, 255, 255
$starCommandBlue: #0079b3 !default; //Star Command Blue //0, 121, 179
$tangerine: #f08300 !default; //Tangerine //240, 131, 0
$lava: #d80c18 !default; //Lava //216, 12, 24
$coralRed: #f93c3c !default; //Coral Red //249, 60, 60
$dimGray: #666666 !default; //Dim Gray  //102, 102, 102
$jet: #333333 !default; //Jet //51, 51, 51
$ashGrey: #b4b4b4 !default; //Ash Grey //180, 180, 180
$whiteSmoke: #f5f5f5 !default; //White Smoke //245, 245, 245
$antiFlashWhite: #f2f2f2 !default; //Anti-Flash White //242, 242, 242
$spanishGray: #999999 !default; //Spanish Gray //153, 153, 153  //2
$snow: #fafafa !default; //Snow //250, 250, 250  //1
$lightGray: #d5d5d5 !default; //Light Gray 2 //213, 213, 213 //2
$auroMetalSaurus: #6c757d !default; //AuroMetalSaurus //108, 117, 125
$sonicSilver: #707070 !default; //Sonic Silver //112, 112, 112
$black: #000000 !default; //Black //0, 0, 0
$pumpkin: #fd7e14 !default; //Pumpkin //253, 126, 20
$sunglow: #ffc977 !default; //Sunglow //255, 198, 39
$mikadoYellow: #ffc107 !default; //Mikado Yellow //255, 198, 39
// màu này tên từ green mặc dù chưa có green. vì chính xác tone green rgb(0, 255, 0) là Electric Green
$green: #68b022 !default; //Green //104, 176, 34
$glitter: #ebeef7 !default; //Glitter //235, 238, 247
$davysGrey: #5e5e5e !default; //Davy's Grey //94, 94, 94
$bdazzledBlue: #385c8e !default; //B'dazzled Blue //56, 92, 142
$azure: #0084ff !default; //Azure //0, 132, 255
$seaGreen: #28a745 !default; //seaGreen //40, 167, 69,
$rustyRed: #dc3545 !default; //Rusty Red //220, 53, 69
$platinum: #e7e7e7 !default; //Platinum //231, 231, 231
$mediumCarmine: #a94442 !default; //Medium Carmine //169, 68, 66
$mistyRose: #ffdfdf !default; //Misty Rose //255, 223, 223
$jasper: #d03f3b !default; //Jasper //208, 63, 59
$charcoal: #333351 !default; //Charcoal //51, 51, 81
$charlestonGreen: #212529 !default; //Charleston Green //33, 37, 41
$eerieBlack: #1e1e20 !default; //Eerie Black //30, 30, 32
$darkGrayX11: #aaaaaa !default; //Dark Gray (X11) //170, 170, 170
$taupeGray: #888888 !default; //Taupe Gray //136, 136, 136
$isabelline: #eeeeee !default; //Isabelline //238, 238, 238
$silverSand: #c1c3c4 !default; //Silver Sand //193, 195, 196
$aliceBlue: #edf5f9 !default; //Alice Blue //237, 245, 249
$brightCerulean: #2a9fd8 !default; //Bright Cerulean //42, 159, 216
$darkLiver: #515151 !default; //Dark Liver 81, 81, 81
$ashGrey3b4: #b1b3b4 !default; // Ash Grey 177, 179, 180
$lightGray2d4: #d1d2d4 !default; // Light Gray 209, 210, 212
$davysGrey85a: #58585a !default; // Davy's Grey 88, 88, 90
$vividCerulean: #00a1e4 !default; // Vivid Cerulean 0, 161, 228
$spanishOrange: #e46a07 !default;

// alpha colors:
$alphaBlack0015: rgba(0, 0, 0, 0.015) !default;
$alphaBlack0025: rgba(0, 0, 0, 0.025) !default;
$alphaBlack003: rgba(0, 0, 0, 0.03) !default;
$alphaBlack0035: rgba(0, 0, 0, 0.035) !default;
$alphaBlack005: rgba(0, 0, 0, 0.05) !default;
$alphaBlack0075: rgba(0, 0, 0, 0.075) !default;
$alphaBlack0085: rgba(0, 0, 0, 0.085) !default;
$alphaBlack01: rgba(0, 0, 0, 0.1) !default;
$alphaBlack0125: rgba(0, 0, 0, 0.125) !default;
$alphaBlack015: rgba(0, 0, 0, 0.15) !default;
$alphaBlack016: rgba(0, 0, 0, 0.16) !default;
$alphaBlack018: rgba(0, 0, 0, 0.18) !default;
$alphaBlack02: rgba(0, 0, 0, 0.2) !default;
$alphaBlack025: rgba(0, 0, 0, 0.25) !default;
$alphaBlack03: rgba(0, 0, 0, 0.3) !default;
$alphaBlack036: rgba(0, 0, 0, 0.36) !default;
$alphaBlack04: rgba(0, 0, 0, 0.4) !default;
$alphaBlack045: rgba(0, 0, 0, 0.45) !default;
$alphaBlack05: rgba(0, 0, 0, 0.5) !default;
$alphaBlack075: rgba(0, 0, 0, 0.75) !default;
$alphaBlack09: rgba(0, 0, 0, 0.9) !default;
$alphaRichElectricBlue01: rgba($richElectricBlue, 0.1) !default;
$alphaWhite06: rgba($white, 0.6) !default;
$alphaWhite013: rgba($white, 0.13) !default;
$alphaWhite05: rgba($white, 0.5) !default;
$alphaWhite0: rgba($white, 0) !default;
$alphaWhite1: rgba($white, 1) !default;
$alphaIsabelline02: rgba($isabelline, 0.2) !default;

$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #6c757d !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;

// map theme:
$theme-colors: (
  ) !default;
$theme-colors: map-merge(("primary": $richElectricBlue,
      "secondary": $pictonBlue,
      "success": $green,
      "info": $pictonBlue,
      "warning": $sunglow,
      "danger": $lava,
      "light": $lightGray,
      "dark": $jet,
    ),
    $theme-colors
);

// fonts:
$roboto: "Roboto", sans-serif;
$openSans: "Open Sans", sans-serif;
$enableGridClasses: true !default;
$robotoSmall: 200 normal 12px/16px "Roboto", sans-serif;
$robotoMedium: 500 normal 16px/24px "Roboto", sans-serif;

$fontSizeBase: 1rem !default; // Assumes the browser default, typically `16px`
$fontWeightNormal: 400 !default;
$fontWeightBase: $fontWeightNormal !default;
$lineHeightBase: 1.5 !default;

$fontFamilySansSerif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
  "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
  "Segoe UI Symbol" !default;
$fontFamilyMonospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono",
  "Courier New", monospace !default;
$fontFamilyBase: $roboto !default;

// Options
//
// Quickly modify global styling by enabling or disabling optional features.
$enable-caret: true !default;
$enable-rounded: true !default;
$enable-shadows: false !default;
$enable-gradients: true !default;
$enable-transitions: true !default;
$enable-hover-media-query: false !default; // Deprecated, no longer affects any compiled CSS
$enable-grid-classes: true !default;
$enable-print-styles: true !default;
// Spacing
//
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the $spacers map, should you need more variation.
// stylelint-disable
$spacer: 1rem !default;
// This variable affects the `.h-*` and `.w-*` classes.
// stylelint-enable
// Body
//
// Settings for the `<body>` element.
$body-bg: $white !default;
$body-color: $gray-900 !default;
// Links
//
// Style anchor elements.
$link-color: theme-color("primary") !default;
$link-decoration: none !default;
$link-hover-color: darken($richElectricBlue, 15%) !default;
$link-hover-decoration: underline !default;
// Paragraphs
//
// Style p element.
$paragraph-margin-bottom: 1rem !default;

// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.
$gridBreakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1280px,
  ) !default;
// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.
$containerMaxWidths: (
  sm: 540px,
  md: 720px,
  lg: 1140px,
  xl: 1140px,
  xxl: 1270px,
  ) !default;

// Transition:
$duration: 0.3s;
$longDuration: 0.5s;
$ySpacingTransformAnimation: -5px;
$easing: ease;
$baseCubic: cubic-bezier(0.075, 0.82, 0.165, 1
);

// Grid columns
//
// Set the number of columns and specify the width of the gutters.
$gridColumns: 12 !default;
$gridGutterWidth: 30px !default;
// Components
//
// Define common padding and border radius sizes and more.
$line-height-lg: 1.5 !default;
$line-height-sm: 1.5 !default;
$border-width: 1px !default;
$border-color: $gray-300 !default;
$border-radius: 0.25rem !default;
$border-radius-lg: 0.3rem !default;
$border-radius-sm: 0.2rem !default;
$box-shadow-sm: 0 0.125rem 0.25rem rgba($black, 0.075) !default;
$box-shadow: 0 0.5rem 1rem rgba($black, 0.15) !default;
$box-shadow-lg: 0 1rem 3rem rgba($black, 0.175) !default;
$component-active-color: $white !default;
$component-active-bg: theme-color("primary") !default;
$caret-width: 0.3em !default;
$transitionBase: all 0.2s ease-in-out !default;
$transition-fade: opacity 0.15s linear !default;
$transition-collapse: height 0.35s ease !default;
$transition-btn: background 0.3s ease, border 0.3s ease, opacity 0.3s ease,
  box-shadow 0.3s ease;
$baseShadow: $box-shadow !default;
// Fonts
//
// Font, line-height, and color for body text, headings, and more.
// stylelint-disable value-keyword-case
$font-path: "../fonts/" !default;
$font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
  "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
  "Segoe UI Symbol" !default;
$font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas,
  "Liberation Mono", "Courier New", monospace !default;
$font-family-base: $font-family-sans-serif !default;
// stylelint-enable value-keyword-case
$font-size-base: 1rem !default; // Assumes the browser default, typically `16px`
$font-size-lg: (
  $font-size-base * 1.25) !default;
$font-size-sm: (
  $font-size-base * 0.875) !default;
$font-weight-light: 300 !default;
$font-weight-normal: 400 !default;
$font-weight-bold: 700 !default;
$font-weight-base: $font-weight-normal !default;
$line-height-base: 1.5 !default;
$h1-font-size: $font-size-base * 2.5 !default;
$h2-font-size: $font-size-base * 2 !default;
$h3-font-size: $font-size-base * 1.75 !default;
$h4-font-size: $font-size-base * 1.5 !default;
$h5-font-size: $font-size-base * 1.25 !default;
$h6-font-size: $font-size-base !default;
$headings-margin-bottom: (
  $spacer / 2) !default;
$headings-font-family: inherit !default;
$headings-font-weight: 500 !default;
$headings-line-height: 1.2 !default;
$headings-color: inherit !default;
$display1-size: 6rem !default;
$display2-size: 5.5rem !default;
$display3-size: 4.5rem !default;
$display4-size: 3.5rem !default;
$display1-weight: 300 !default;
$display2-weight: 300 !default;
$display3-weight: 300 !default;
$display4-weight: 300 !default;
$display-line-height: $headings-line-height !default;
$lead-font-size: (
  $font-size-base * 1.25) !default;
$lead-font-weight: 300 !default;
$small-font-size: 80% !default;
$text-muted: $gray-600 !default;
$blockquote-small-color: $gray-600 !default;
$blockquote-font-size: (
  $font-size-base * 1.25) !default;
$hr-border-color: rgba($black, 0.1) !default;
$hr-border-width: $border-width !default;
$mark-padding: 0.2em !default;
$dt-font-weight: $font-weight-bold !default;
$kbd-box-shadow: inset 0 -0.1rem 0 rgba($black, 0.25) !default;
$nested-kbd-font-weight: $font-weight-bold !default;
$list-inline-padding: 0.5rem !default;
$mark-bg: #fcf8e3 !default;
$hr-margin-y: $spacer !default;
$gray-bg: #f5f5f5 !default;
$border_input_d: $dimGray !default;
$table-cell-padding: 0.75rem !default;

%checkIconCss {
  content: "";
  display: inline-block;
  transform: rotate(45deg
  );
height: 20px;
width: 8px;
border-bottom: 2px solid white;
border-right: 2px solid white;
}