// part 1:
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.bold {
  font-weight: bold;
}

button {
  &:focus {
    outline: 0;
  }
}
[role="button"] {
  &:focus {
    outline: 0;
  }
}
[role="tab"] {
  &:focus {
    outline: 0;
  }
}

img {
  max-width: 100%;
  height: auto;
}

caption {
  color: $auroMetalSaurus;
}
