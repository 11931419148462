.m {
  &#{t}-0 {
    margin-top: 0 !important;
  }
  &#{t}-1 {
    margin-top: $spacer * 0.25 !important;
  }
  &#{t}-3 {
    margin-top: $spacer !important;
  }
  &-auto {
    margin: auto !important;
  }
}
.p {
  &#{b}-0 {
    padding-top: 0 !important;
  }
}
