@import "sass/common-imports.scss";

.o-loginform {
  margin: 0 auto;
  max-width: 820px;
  box-shadow: 0 1px 6px 0 $alphaBlack03;
  display: flex;
  border-radius: 12px;
  width: auto;
  &_left {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    width: 315px;
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
    @include break-max(991px) {
      display: none;
    }
    &_text {
      max-width: 210px;
    }
    &_img {
      height: 100%;
      .a-image {
        height: 100%;
        img {
          height: 100%;
        }
      }
    }
  }
  &_right {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: 0 4%;
    @include break-max(991px) {
      width: 100%;
    }
    &_title {
      margin-top: 20px;
    }
    &_desc {
      margin-top: 14px;
      margin-bottom: 24px;
    }
    &_form {
      .a-checkbox {
        justify-content: flex-start;
        margin-bottom: 16px;
        &_input {
          margin-left: 0px;
        }
      }
      &_signin {
        margin-top: 12px;
        display: flex;
        justify-content: space-between;
        .a-button {
          padding: 4px 14px;
          width: 48%;
        }
      }
      &_signup {
        margin-top: 12px;
        display: flex;
        justify-content: center;
        .a-button {
          padding: 6px 16px;
          width: 48%;
        }
      }
      &_error-text {
        margin-bottom: 16px;
      }
    }
    &_ask-register {
      margin-top: 16px;
      .a-text {
        span {
          font-weight: bold;
          text-decoration: underline;
        }
      }
    }
    &_hr {
      hr {
        height: 1px;
        width: 80%;
        background-color: $pictonBlue;
      }
    }
    &_register-with {
      text-align: center;
    }

    &_social-btn {
      display: flex;
      justify-content: center;
      margin-top: 16px;
      margin-bottom: 20px;
      @include break-max(539px) {
        flex-direction: column;
        align-items: center;
      }
      .a-button-login {
        width: 48%;
        margin: 0;
        @include break-max(539px) {
          width: 100%;
        }
      }
      .a-button-google {
        @include break-max(539px) {
          margin-top: 8px;
        }
      }
    }
  }

  &-closeModalSignUp {
    border: 0;
      position: absolute;
      right: 0;
      top: 0;
      cursor: pointer;
  }
}
